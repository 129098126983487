import { ErrorCode, useDropzone } from 'react-dropzone';
import { useSnackbar } from './useSnackbar';
import { useTranslation } from 'react-i18next';
import { useUploadSiteFile } from 'api/site';
import { AxiosError } from 'axios';

const MAX_FILES = 1;
const MAX_FILE_SIZE = 450_000;

const useDropzoneErrorMessage = () => {
  const { t } = useTranslation();

  return (code: ErrorCode | string, file?: File) => {
    switch (code) {
      case ErrorCode.TooManyFiles:
        return t('too_many_files_selected', { maxFiles: MAX_FILES });
      case ErrorCode.FileTooLarge:
        return t('file_is_too_large', { maxSize: `${MAX_FILE_SIZE / 1000}kB` });
      case ErrorCode.FileTooSmall:
        return t('file_is_too_small');
      case ErrorCode.FileInvalidType:
        return t('invalid_file_type', { type: file?.type });
      default:
        return t('could_not_upload_file');
    }
  };
};

export const useSiteFileDropzone = (siteId: string, path: string) => {
  const { enqueueSnackbar } = useSnackbar();
  const createSiteFile = useUploadSiteFile(siteId);
  const getErrorMessage = useDropzoneErrorMessage();

  return useDropzone({
    onDrop: async (acceptedFiles, fileRejections) => {
      let showedTooManyFilesError = false;

      let errorMessages: string[] = [];

      for (const rejection of fileRejections) {
        for (const error of rejection.errors) {
          if (showedTooManyFilesError) {
            continue;
          }

          if (error.code === ErrorCode.TooManyFiles) {
            errorMessages = [getErrorMessage(ErrorCode.TooManyFiles)];
            showedTooManyFilesError = true;
            continue;
          }

          const multipleFilesSelected = acceptedFiles.length + fileRejections.length > 1;
          const displayedMessage = multipleFilesSelected
            ? `${getErrorMessage(error.code, rejection.file)} (${rejection.file.name})`
            : getErrorMessage(error.code, rejection.file);

          errorMessages.push(displayedMessage);
        }
      }

      if (errorMessages.length) {
        enqueueSnackbar(errorMessages.join(', '), {
          variant: 'error',
        });
      }

      for (const file of acceptedFiles) {
        try {
          const content = await file.text();

          await createSiteFile.mutateAsync({
            filename: file.name,
            path: path ?? '',
            content,
          });
        } catch (error) {
          if (error instanceof AxiosError && error.code === 'NETWORK_ERROR') {
            // Cannot detect status code 413 from AxiosError, assume that all network errors are due to file size
            enqueueSnackbar(getErrorMessage(ErrorCode.FileTooLarge), {
              variant: 'error',
            });
          } else {
            throw error;
          }
        }
      }
    },
    noClick: true,
    noKeyboard: true,
    maxFiles: MAX_FILES,
    maxSize: MAX_FILE_SIZE,
    accept: {
      'application/epub+zip': ['.epub'],
      'application/json': ['.json'],
      'application/ld+json': ['.jsonld'],
      'application/msword': ['.doc'],
      'application/pdf': ['.pdf'],
      'application/rtf': ['.rtf'],
      'application/vnd.amazon.ebook': ['.azw'],
      'application/vnd.ms-excel': ['.xls'],
      'application/vnd.ms-powerpoint': ['.ppt'],
      'application/vnd.oasis.opendocument.presentation': ['.odp'],
      'application/vnd.oasis.opendocument.spreadsheet': ['.ods'],
      'application/vnd.oasis.opendocument.text': ['.odt'],
      'application/vnd.openxmlformats-officedocument.presentationml.presentation': ['.pptx'],
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
      'application/xhtml+xml': ['.xhtml'],
      'application/xml': ['.xml'],
      'application/zip': ['.zip'],
      'audio/*': [],
      'font/otf': ['.otf'],
      'font/ttf': ['.ttf'],
      'font/woff': ['.woff'],
      'font/woff2': ['.woff2'],
      'image/*': [],
      'text/*': [],
      'video/*': [],
    },
  });
};
