import { useQuery, useMutation, useQueryClient, keepPreviousData } from '@tanstack/react-query';
import { AxiosService } from 'api/axiosService';
import { PaginatedListState, serializeParamsForQuery } from 'component/hooks/usePaginatedListState';
import { components } from 'openapi-types';

export type Plugin = components['schemas']['Plugin'];

interface Payload {
  // TODO remove title and use generated API types when we remove the old design pages
  title?: string;
}
export interface ActivatePayload extends Payload {
  plugin: string;
  status: 'activate' | 'deactivate';
}
export interface UpgradePayload extends Payload {
  plugin: string;
  update: boolean;
}

export interface DeletePayload extends Payload {
  plugins: string;
}

// TODO remove this when we remove the old design pages
interface FetchPluginsRequest {
  siteId: string;
  page: number;
  perPage?: number;
  browse?: string;
  query?: string;
}

export type NewPlugin = NonNullable<components['schemas']['SearchPluginsResult']['result']>[number];

// TODO remove this when we remove the old design pages
export const usePluginList = (id: string) => {
  return useQuery({
    queryKey: ['installed-plugins'],
    queryFn: async () =>
      await AxiosService.get<components['schemas']['ListPluginsResponse']>(`sites/${id}/plugins`),
    enabled: true,
  });
};

export const useInstalledPluginList = (state: PaginatedListState, siteId: string) => {
  const serializedParams = serializeParamsForQuery(state);

  return useQuery({
    queryKey: ['installed-plugins', siteId, serializedParams],
    queryFn: async () =>
      await AxiosService.get<components['schemas']['ListPluginsResponse']>(
        `sites/${siteId}/plugins?${serializedParams}`
      ),
  });
};

export const useDeletePlugin = (id: string) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: [`delete-plugin-${id}`],

    mutationFn: async (data: components['schemas']['DeletePluginRequest']) =>
      await AxiosService.delete<components['schemas']['DeletePluginResponse']>(
        `sites/${id}/plugins`,
        {
          data,
        }
      ),

    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['installed-plugins'],
      });
      await queryClient.invalidateQueries({
        queryKey: ['plugins'],
      });
    },
  });
};

export const useChangeActiveStatus = (id: string) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: [`change-plugin-${id}`],
    mutationFn: async (data: ActivatePayload) =>
      await AxiosService.patch(`sites/${id}/plugins`, data),

    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['installed-plugins'],
      });
    },
  });
};

export const useUpdatePlugin = (id: string) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: [`update-plugin-${id}`],

    mutationFn: async (data: UpgradePayload) =>
      await AxiosService.put<components['schemas']['UpdatePluginResponse']>(
        `sites/${id}/plugins`,
        data
      ),

    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['installed-plugins'],
      });
      await queryClient.invalidateQueries({
        queryKey: ['plugins'],
      });
    },
  });
};

// TODO remove this when we remove the old design pages
export const useGetPlugins = ({ siteId, page, perPage, browse, query }: FetchPluginsRequest) => {
  let queryParams = `page=${page}&per_page=${perPage}`;

  if (browse) {
    queryParams = `${queryParams}&browse=${browse}`;
  }
  if (query) {
    queryParams = `${queryParams}&query=${query}`;
  }

  return useQuery({
    queryKey: ['plugins', page, browse, query, perPage],

    queryFn: async () =>
      await AxiosService.get<components['schemas']['SearchPluginsResponse']>(
        `sites/${siteId}/plugins/search?${queryParams}`
      ),

    enabled: false,
    placeholderData: keepPreviousData,
    refetchOnWindowFocus: false,
  });
};

export const useSearchPlugins = (state: PaginatedListState, siteId: string) => {
  const serializedParams = serializeParamsForQuery(state);

  return useQuery({
    queryKey: ['plugins', siteId, serializedParams],
    queryFn: async () =>
      await AxiosService.get<components['schemas']['SearchPluginsResponse']>(
        `sites/${siteId}/plugins/search?${serializedParams}`
      ),
  });
};

export const useInstallPlugin = (siteId: string) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: [`install-plugin-${siteId}`],

    mutationFn: async (data: components['schemas']['InstallPluginsRequest']) =>
      await AxiosService.post<components['schemas']['InstallPluginsResponse']>(
        `sites/${siteId}/plugins`,
        data
      ),

    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['plugins'],
      });
      await queryClient.invalidateQueries({
        queryKey: ['installed-plugins'],
      });
    },
  });
};
