import { AxiosInternalService, AxiosService } from 'api/axiosService';
import { ApiResponse, SiteDetailsData, PaginatedApiResponse } from './types';
import { useQuery } from '@tanstack/react-query';
import { PaginatedListState, serializeParamsForQuery } from 'component/hooks/usePaginatedListState';
import { components } from 'openapi-types';

export interface Response<T> {
  data: {
    viewer: {
      zones: [T];
    };
  };
}

export interface SiteMetrics extends Response<SiteMetricsZoneData> {
  dataTransferTotal: number;
  requestsTotal: number;
  success: boolean;
}

interface SiteMetricsZoneData {
  data_transferTotals: Array<{
    dimensions: {
      ts: string;
    };
    sum: {
      edgeResponseBytes: number;
    };
  }>;
  requestsTotals: Array<{
    count: number;
    dimensions: {
      ts: string;
    };
  }>;
}

export const useSiteMetrics = (data: SiteDetailsData & { duration: string }) => {
  const url = `reporting/sites/${data.id}/cdn/requests?duration=${data.duration}`;

  return useQuery({
    queryKey: [`siteMetrics${data.id}`],
    queryFn: async () => await AxiosInternalService.get<SiteMetrics>(url),
  });
};

export interface SiteCdnEdgeOrigin extends Response<SiteCdnData> {
  serveByEdgeTotal: number;
  serveByOriginTotal: number;
  success: boolean;
}

interface SiteCdnData {
  servedByEdge: Array<{
    avg: {
      sampleInterval: number;
    };
    count: number;
    dimensions: {
      ts: string;
    };
  }>;
  servedByOrigin: Array<{
    avg: {
      sampleInterval: number;
    };
    count: number;
    dimensions: {
      ts: string;
    };
  }>;
}

export const useSiteCdnEdgeOrigin = (id: string, duration: string) => {
  const url = `reporting/sites/${id}/cdn/requests?duration=${duration}&req_type=edgeOrigin`;

  return useQuery({
    queryKey: [`siteCdnEdgeOrigin${id}`],
    queryFn: async () => await AxiosService.get<SiteCdnEdgeOrigin>(url),
  });
};

export interface SiteCacheStatus
  extends Response<{
    cacheStatus: Array<{
      avg: {
        sampleInterval: number;
      };
      count: number;
      dimensions: {
        cacheStatus: string;
      };
      sum: {
        edgeResponseBytes: number;
      };
    }>;
  }> {}

export const useSiteCacheStatus = (data: SiteDetailsData & { duration: string }) => {
  const url = `reporting/sites/${data.id}/cdn/cache-status?duration=${data.duration}`;

  return useQuery({
    queryKey: ['siteCacheStatus', data.id, data.duration],
    queryFn: async () => await AxiosService.get<SiteCacheStatus>(url),
    retry: 0,
    gcTime: 0,
  });
};

export interface SiteCacheContent
  extends Response<{
    cacheStatusByContentType: Array<{
      avg: {
        sampleInterval: number;
      };
      count: number;
      dimensions: {
        cacheStatus: string;
        edgeResponseContentTypeName: string;
      };
      sum: {
        edgeResponseBytes: number;
      };
    }>;
  }> {}

export const useSiteCacheContent = (data: SiteDetailsData & { duration: string }) => {
  const url = `reporting/sites/${data.id}/cdn/cache-content?duration=${data.duration}`;

  return useQuery({
    queryKey: [`siteCacheContent${data.id}`],
    queryFn: async () => await AxiosService.get<SiteCacheContent>(url),
  });
};

export interface SiteCacheTopDatum {
  avg: {
    sampleInterval: number;
  };
  count: number;
  dimensions: {
    metric: string;
  };
  sum: {
    edgeResponseBytes: number;
  };
}

export interface SiteCacheTop
  extends Response<{
    topContentTypes: Array<SiteCacheTopDatum>;
    topCountries: Array<SiteCacheTopDatum>;
    topDeviceTypes: Array<SiteCacheTopDatum>;
    topHosts: Array<SiteCacheTopDatum>;
    topQueryStrings: Array<SiteCacheTopDatum>;
    topStatusCodes: Array<SiteCacheTopDatum>;
    topUriPaths: Array<SiteCacheTopDatum>;
    total: Array<{
      avg: {
        sampleInterval: number;
      };
      count: number;
      sum: {
        edgeResponseBytes: number;
      };
    }>;
  }> {}

export const useSiteCacheTop = (data: SiteDetailsData & { duration: string }) => {
  const url = `reporting/sites/${data.id}/cdn/cache-top?duration=${data.duration}`;

  return useQuery({
    queryKey: [`siteCacheTop${data.id}`],
    queryFn: async () => await AxiosService.get<SiteCacheTop>(url),
  });
};
export interface FirewallSeries {
  avg: {
    sampleInterval: number;
  };
  count: number;
  dimensions: {
    metric: string;
    ts: string;
  };
}

export interface EventZone {
  firewallEventsAdaptiveGroups: Array<FirewallAdaptiveGroups>;
}
export interface FirewallAdaptiveGroups {
  avg: {
    sampleInterval: number;
  };
  count: number;
  dimensions: {
    source: string;
  };
}

export const useGetFirewallEvents = (siteId: string, duration: string) => {
  return useQuery({
    queryKey: [`wafFirewallEvents-${siteId}`],

    queryFn: async () =>
      AxiosService.get(`reporting/sites/${siteId}/waf/firewall-events?duration=${duration}`),
  });
};

export const useGetWAFEventReports = (siteId: string, duration: string) => {
  return useQuery({
    queryKey: [`wafEventServices-${siteId}`],

    queryFn: async () =>
      AxiosService.get<Response<EventZone>>(
        `reporting/sites/${siteId}/waf/events-services?duration=${duration}`
      ),
  });
};

export interface SourceZone {
  avg: {
    sampleInterval: number;
  };
  count: number;
  dimensions: {
    metric: string;
  };
}
export interface EventSourceZones {
  topASNs: SourceZone[];
  topCountries: SourceZone[];
  topFirewallRules: SourceZone[];
  topHosts: SourceZone[];
  topHttpMethods: SourceZone[];
  topIPs: SourceZone[];
  topRateLimitRules: SourceZone[];
  topUriPaths: SourceZone[];
  topUserAgents: SourceZone[];
  topWAFRules: SourceZone[];
  total: [
    {
      avg: {
        sampleInterval: number;
      };
      count: number;
    },
  ];
}

export const useGetEventSources = (siteId: string, duration: string) => {
  return useQuery({
    queryKey: [`wafEventSources-${siteId}`],

    queryFn: async () =>
      AxiosService.get<Response<EventSourceZones>>(
        `reporting/sites/${siteId}/waf/events-source?duration=${duration}`
      ),
  });
};

export interface EventTimeReportResponse extends Response<EventTimeReport> {
  total: [
    {
      avg: {
        sampleInterval: number;
      };
      count: number;
    },
  ];
}

export interface FormattedEventTimeReports {
  activity: EventTimeReport[];
  total: EventTimeTotal;
}

export type EventTimeTotal = [
  {
    avg: {
      sampleInterval: number;
    };
    count: number;
  },
];
export interface EventTimeReport {
  action: string;
  clientASNDescription: string;
  clientAsn: string;
  clientCountryName: string;
  clientIP: string;
  clientRequestHTTPHost: string;
  clientRequestHTTPMethodName: string;
  clientRequestHTTPProtocol: string;
  clientRequestPath: string;
  clientRequestQuery: string;
  datetime: string;
  matchIndex: number;
  metadata: EventTimeMetaData[];
  rayName: string;
  ruleId: string;
  sampleInterval: number;
  source: string;
  userAgent: string;
}

export type ReportTypes =
  | 'action'
  | 'clientASNDescription'
  | 'clientAsn'
  | 'clientCountryName'
  | 'clientIP'
  | 'clientRequestHTTPHost'
  | 'clientRequestHTTPMethodName'
  | 'clientRequestHTTPProtocol'
  | 'clientRequestPath'
  | 'clientRequestQuery'
  | 'datetime'
  | 'matchIndex'
  | 'metadata'
  | 'rayName'
  | 'ruleId'
  | 'sampleInterval'
  | 'source'
  | 'userAgent';

interface EventTimeMetaData {
  key: string;
  value: string;
}

export const useGetEventTimeReport = (siteId: string, duration: string) => {
  return useQuery({
    queryKey: [`wafEventTimeReports-${siteId}`],

    queryFn: async () =>
      AxiosService.get(`reporting/sites/${siteId}/waf/events?duration=${duration}`),
  });
};

export type SiteVisitors = {
  total: number;
  visitors: Array<{
    date: string;
    visitors: number;
  }>;
};

export const useGetSiteVisitors = (siteId: string, startDate: string, endDate: string) => {
  return useQuery({
    queryKey: [`siteVisitors-${siteId}-${startDate}-${endDate}`],

    queryFn: async () =>
      AxiosService.get<ApiResponse<SiteVisitors>>(
        `sites/${siteId}/visitors?date_from=${startDate}&date_to=${endDate}`
      ),

    retry: 0,
    staleTime: 5000,
    gcTime: 10,
  });
};

export interface ActivityLogPaginationState {
  perPage: number;
  activePageNumber: number;
  filter: string;
  siteId: string;
  dateFrom?: string;
  dateTo?: string;
}

export type ActivityLogRow = {
  id: number;
  author: string;
  site_id: number;
  display_name: string;
  user_login: string;
  user_email: string;
  roles: string;
  ip: string;
  type: string;
  label: string;
  action: string;
  description: string;
  created_at: string;
};

export const useActivityLog = ({
  siteId,
  perPage,
  activePageNumber,
  filter,
  dateFrom,
  dateTo,
}: ActivityLogPaginationState) => {
  let url = `sites/${siteId}/activity/events?page=${activePageNumber}&per_page=${perPage}`;

  if (filter) {
    url += `&display_name=${filter}`;
  }

  if (dateFrom && dateTo) {
    url += `&date_from=${dateFrom}&date_to=${dateTo}`;
  }

  return useQuery({
    queryKey: [`site-logs-${siteId}`],
    queryFn: async () => await AxiosService.get<PaginatedApiResponse<ActivityLogRow[]>>(url),
    retry: 0,
    gcTime: 0,
  });
};

export type Event = components['schemas']['Event'];

export const useActivityLogNew = (siteId: string, state: PaginatedListState, enabled: boolean) => {
  const serializedParams = serializeParamsForQuery(state);

  return useQuery({
    queryKey: ['site-logs', siteId, serializedParams],
    queryFn: async () =>
      await AxiosService.get<components['schemas']['ListEventsResponse']>(
        `sites/${siteId}/activity/events?${serializedParams}`
      ),
    enabled,
    retry: 0,
    gcTime: 0,
  });
};

export type AccessLogPaginationState = {
  duration: string;
  domain: string;
  ip?: string;
  cacheStatus?: string;
  uri?: string;
  rayId?: string;
  deviceType?: string;
  statusCode?: string;
  perPage: number;
  siteId: string;
  activePageNumber: number;
};

export type AccessLogRow = {
  CacheCacheStatus: string;
  ClientCountry: string;
  ClientDeviceType: string;
  ClientIP: string;
  ClientRequestHost: string;
  ClientRequestMethod: string;
  ClientRequestURI: string;
  ClientRequestUserAgent: string;
  OriginResponseStatus: number;
  EdgeResponseStatus: number;
  RayID: string;
  timestamp: string;
};

export const useAccessLogs = (
  {
    cacheStatus,
    domain,
    duration,
    deviceType,
    ip,
    rayId,
    statusCode,
    siteId,
    uri,
    perPage,
    activePageNumber,
  }: AccessLogPaginationState,
  options?: { enabled: boolean }
) => {
  let baseUrl = `sites/${siteId}/access-logs?duration=${duration}&domain=${domain}&per_page=${perPage}&page=${activePageNumber}`;

  if (cacheStatus) {
    baseUrl += `&cache_status=${cacheStatus}`;
  }

  if (typeof ip === 'string') {
    baseUrl += `&ip=${ip}`;
  }

  if (typeof uri === 'string') {
    baseUrl += `&uri=${uri}`;
  }

  if (typeof rayId === 'string') {
    baseUrl += `&ray_id=${rayId}`;
  }

  if (typeof deviceType === 'string') {
    baseUrl += `&device_type=${deviceType}`;
  }

  if (typeof statusCode === 'string') {
    baseUrl += `&status_code=${statusCode}`;
  }

  return useQuery({
    queryKey: [`site-access-logs-${siteId}`],
    queryFn: async () => await AxiosService.get<PaginatedApiResponse<AccessLogRow[]>>(baseUrl),
    ...options,
    retry: 0,
    gcTime: 0,
  });
};

export const useAccessLogNew = (
  siteId: string,
  domain: string | undefined,
  state: PaginatedListState
) => {
  const serializedParams = `${serializeParamsForQuery(state)}&domain=${domain}`;

  return useQuery({
    queryKey: ['site-access-logs', siteId, serializedParams],
    queryFn: async () =>
      await AxiosService.get<PaginatedApiResponse<AccessLogRow[]>>(
        `sites/${siteId}/access_logs?${serializedParams}`
      ),
    enabled: !!domain,
    retry: 0,
    gcTime: 0,
  });
};

type SiteBandwidthUsage = {
  data: {
    viewer: {
      zones: Array<{
        series: Array<{
          avg: {
            sampleInterval: number;
          };
          count: number;
          dimensions: {
            ts: string;
          };
          sum: {
            edgeResponseBytes: number;
            visits: number;
          };
        }>;
      }>;
    };
    success: boolean;
  };
};

// Bandwidth Metrics
export const useSiteBandwidthUsage = (data: SiteDetailsData & { duration: string }) => {
  const url = `reporting/sites/${data.id}/bandwidth/usage?duration=${data.duration}`;

  return useQuery({
    queryKey: [`siteBandwidthUsage${data.id}-${data.duration}`],
    queryFn: async () => await AxiosService.get<SiteBandwidthUsage>(url),
  });
};

// Bandwidth Metrics
export const useSiteBandwidthUsageNew = (siteId: string | number, duration: string) => {
  return useQuery({
    gcTime: 0,
    queryKey: ['siteBandwidthUsage', siteId, duration],
    queryFn: async () => {
      await new Promise(resolve => setTimeout(resolve, 1000));
      const now = Date.now();
      const durationMinutes = parseInt(duration) * (duration.at(-1) === 'h' ? 1 : 24) * 60;
      let stepMinutes;
      if (durationMinutes <= 60) {
        stepMinutes = 10;
      } else if (durationMinutes <= 60 * 6) {
        stepMinutes = 30;
      } else if (durationMinutes <= 60 * 24) {
        stepMinutes = 60;
      } else if (durationMinutes <= 60 * 72) {
        stepMinutes = 60 * 4;
      } else if (durationMinutes <= 60 * 24 * 7) {
        stepMinutes = 60 * 12;
      } else {
        stepMinutes = 60 * 24;
      }
      const data = new Array(duration === '1h' ? 0 : durationMinutes / stepMinutes)
        .fill(0)
        .map((_, i) => {
          const edgeRequests = 500 + Math.floor(Math.random() * 300);
          const originRequests = Math.floor(Math.random() * 300);
          const wafEvents = Math.floor(Math.random() * 100);

          return {
            totalRequests: edgeRequests + originRequests,
            edgeRequests,
            originRequests,
            wafEvents,
            time: now - i * stepMinutes * 60 * 1000,
          };
        });

      const totals = {
        totalRequests: data.reduce((acc, curr) => acc + curr.totalRequests, 0),
        edgeRequests: data.reduce((acc, curr) => acc + curr.edgeRequests, 0),
        originRequests: data.reduce((acc, curr) => acc + curr.originRequests, 0),
        wafEvents: data.reduce((acc, curr) => acc + curr.wafEvents, 0),
      };

      const percentages = {
        edgeRequests: (totals.edgeRequests / totals.totalRequests) * 100,
        originRequests: (totals.originRequests / totals.totalRequests) * 100,
      };

      return {
        metadata: {
          totals,
          percentages,
        },
        result: data,
      };
    },
  });
};

export type SiteBandwidthTopUsageMetric = {
  avg: {
    sampleInterval: number;
  };
  count: number;
  dimensions: {
    metric: string;
    description: string;
  };
  sum: {
    edgeResponseBytes: number;
    visits: number;
  };
};

export type SiteBandwidthTopUsage = {
  data: {
    viewer: {
      zones: Array<{
        __typename: string;
        countries: Array<SiteBandwidthTopUsageMetric>;
        methods: Array<SiteBandwidthTopUsageMetric>;
        topASNs: Array<SiteBandwidthTopUsageMetric>;
        topBrowsers: Array<SiteBandwidthTopUsageMetric>;
        topCacheStatuses: Array<SiteBandwidthTopUsageMetric>;
        topClientIPs: Array<SiteBandwidthTopUsageMetric>;
        topColoCodes: Array<SiteBandwidthTopUsageMetric>;
        topDeviceTypes: Array<SiteBandwidthTopUsageMetric>;
        topEdgeStatusCodes: Array<SiteBandwidthTopUsageMetric>;
        topHTTPProtocolVersions: Array<SiteBandwidthTopUsageMetric>;
        topHosts: Array<SiteBandwidthTopUsageMetric>;
        topOSs: Array<SiteBandwidthTopUsageMetric>;
        topOriginStatusCodes: Array<SiteBandwidthTopUsageMetric>;
        topPaths: Array<SiteBandwidthTopUsageMetric>;
        topReferers: Array<SiteBandwidthTopUsageMetric>;
        topUserAgents: Array<SiteBandwidthTopUsageMetric>;
        topXRequestedWith: Array<SiteBandwidthTopUsageMetric>;
        total: Array<Omit<SiteBandwidthTopUsageMetric, 'dimensions'>>;
      }>;
    };
    success: boolean;
  };
};

export const useSiteBandwidthTopUsage = (data: SiteDetailsData & { duration: string }) => {
  const url = `reporting/sites/${data.id}/bandwidth/top-usage?duration=${data.duration}`;

  return useQuery({
    queryKey: ['siteBandwidthTopUsage', data.id, data.duration],
    queryFn: async () => await AxiosService.get<SiteBandwidthTopUsage>(url),
  });
};

type SiteBandwidth = {
  usages: {
    bandwidth: number;
    date: string;
  }[];
  total: number;
};

export const useSiteBandwidth = (
  data: SiteDetailsData & { startDate: string; endDate: string }
) => {
  const url = `sites/${data.id}/reporting/bandwidth?date_from=${data.startDate}&date_to=${data.endDate}`;

  return useQuery({
    queryKey: [`siteBandwidth${data.id}`],
    queryFn: async () => await AxiosService.get<ApiResponse<SiteBandwidth>>(url),
  });
};
